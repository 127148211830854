import React from "react";

import Layout from "../components/common/layout/layout";
import SEO from "../components/common/layout/seo";
import Navigation from "../components/common/navigation/navigation";

import Signup from "../components/sections/signup";
import Footer from "../components/sections/footer";

const IndexPage = () => (
    <Layout>
        <SEO title="Sign Up" />
        <Navigation />
        <Signup />
        <Footer />
    </Layout>
);

export default IndexPage;
